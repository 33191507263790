<template>
	<v-row dense>
		<v-col cols="12" class="mt-6 ml-6" v-if="stop.portcallnumber">
			<operations-field :label="$t('operations.stops.portcallnumber')" :value="stop.portcallnumber" :inline="true"></operations-field>
		</v-col>
		<v-col cols="12" class="ml-6" v-if="stop.portcallnumber">
			<operations-field :label="$t('operations.stops.shipownername')" :value="stop.consigneename" :inline="true"></operations-field>
		</v-col>
		<v-col cols="12">
			<v-row class="d-flex align-center">
				<v-col cols="1"><v-divider></v-divider></v-col>
				<v-col cols="3">
					<legend class="font-weight-black text-h6" style="color: var(--primary)">{{ $t('stop.vessel') }}</legend>
				</v-col>
				<v-col cols="8"><v-divider></v-divider></v-col>
			</v-row>

			<v-row class="pa-6 pt-1 pb-0" dense>
				<v-col cols="6">
					<operations-field :label="$t('operations.stops.vesselimo')" :value="stop.vesselimo || stop.imo"></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field :label="$t('operations.stops.mmsi')" :value="stop.vesselmmsi || stop.mmsi"></operations-field>
				</v-col>
				<v-col cols="6" v-if="!stop.isAis">
					<operations-field
						:label="$t('operations.stops.vesseltype')"
						:value="stop.vesseltypedescription || stop.shiptype"
					></operations-field>
				</v-col>
				<v-col cols="6" v-else>
					<operations-field
						:label="$t('operations.stops.vesseltype')"
						:value="stop.vesseltypedescription || stop.vesseltype || stop.shiptype"
					></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field
						:label="$t('operations.stops.vessellength')"
						:value="stop.length || stop.vessellength"
						suffix="m"
					></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field :label="$t('operations.stops.vesselbeam')" :value="stop.beam || stop.vesselbeam" suffix="m"></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field
						:label="$t('operations.stops.vesseldraugth')"
						:value="stop.vesseldraugth || stop.draught"
						suffix="m"
					></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field :label="$t('operations.stops.flag')" :value="stop.flag"></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field
						:label="$t('operations.stops.builddatetime')"
						:value="convertDatesFromStopAIS(builddatetime)"
					></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field :label="$t('operations.stops.manager')" :value="stop.manager"></operations-field>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" v-if="stop.portcallnumber">
			<v-row class="d-flex align-center">
				<v-col cols="1"><v-divider></v-divider></v-col>
				<v-col cols="3">
					<legend class="font-weight-black text-h6" style="color: var(--primary)">{{ $t('stop.berth') }}</legend>
				</v-col>
				<v-col cols="8"><v-divider></v-divider></v-col>
			</v-row>

			<v-row class="pa-6 pt-1 pb-0" dense>
				<v-col cols="6">
					<operations-field :label="$t('operations.stops.num')" :value="stop.num"></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field :label="$t('operations.stops.status')" :value="stop.status"></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field :label="$t('operations.stops.berthtype')" :value="stop.berthingtypename"></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field :label="$t('operations.stops.berthingposition')" :value="stop.berthingposition"></operations-field>
				</v-col>
				<v-col cols="12">
					<operations-field :label="$t('operations.stops.berthname')" :value="stop.berthname"></operations-field>
				</v-col>

				<!-- <v-col cols="6" v-if="!stop.statusid || stop.statusid == 'RQ' || stop.statusid == 'IN'">
					<operations-field :label="$t('operations.stops.initialBollard')" :value="stop.inibollard"></operations-field>
				</v-col> -->
				<v-col cols="6">
					<operations-field :label="$t('operations.stops.initialBollard')" :value="stop.inibollard"></operations-field>
				</v-col>
				<v-col cols="6" class="mb-2">
					<operations-field :label="$t('operations.stops.endBollard')" :value="stop.endbollard"></operations-field>
				</v-col>

				<v-col cols="6" v-if="!stop.statusid || stop.statusid == 'RQ' || stop.statusid == 'AC' || stop.statusid == 'IN'">
					<operations-field label="ETA" :value="convertDatesFromStopAIS(stop.eta)"></operations-field>
				</v-col>
				<v-col cols="6" v-else-if="stop.statusid == 'AU'">
					<operations-field label="ETA" :value="convertDatesFromStopAIS(stop.etaauth)"></operations-field>
				</v-col>
				<v-col cols="6" v-else>
					<operations-field label="ETA" :value="convertDatesFromStopAIS(stop.etaactual)"></operations-field>
				</v-col>

				<v-col cols="6" v-if="!stop.statusid || stop.statusid == 'RQ' || stop.statusid == 'AC' || stop.statusid == 'IN'">
					<operations-field label="ETD" :value="convertDatesFromStopAIS(stop.etd)"></operations-field>
				</v-col>
				<v-col cols="6" v-else-if="stop.statusid == 'AU'">
					<operations-field label="ETD" :value="convertDatesFromStopAIS(stop.etdauth)"></operations-field>
				</v-col>
				<v-col cols="6" v-else>
					<operations-field label="ETD" :value="convertDatesFromStopAIS(stop.etdactual)"></operations-field>
				</v-col>

				<v-col cols="6">
					<operations-field label="ATA" :value="convertDatesFromStopAIS(stop.ata)"></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field label="ATD" :value="convertDatesFromStopAIS(stop.atd)"></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field label="ETC" :value="convertDatesFromStopAIS(stop.etc)"></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field label="ATC" :value="convertDatesFromStopAIS(stop.atc)"></operations-field>
				</v-col>
				<v-col cols="6">
					<operations-field :label="$t('operations.stops.activity')" :value="stop.activityname"></operations-field>
				</v-col>
			</v-row>
		</v-col>
		<v-col cols="12" v-if="stop.portcallnumber && $store.getters.getSession.functionalities.includes('MENU_READ_VISIT')">
			<v-row class="d-flex align-center">
				<v-col cols="12"><v-divider></v-divider></v-col>
			</v-row>
			<v-row>
				<v-col cols="11">
					<v-btn block color="primary" class="mx-4" @click="goTo()">{{ $t('operations.stops.moreData') }}</v-btn>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import OperationsField from './OperationsField.vue';

export default {
	components: { OperationsField },
	props: ['stop'],
	data() {
		return {
			stopIMO: null,
			dateFormat: this.$store.getters.dateFormat,
			timeFormat: this.$store.getters.timeFormat
		};
	},
	mounted() {
		this.stopIMO = this.stop.imo;
		this.getAllInfoAis();
	},
	computed: {
		builddatetime() {
			//return this.formatDate(this.stop.builddatetime);
			return this.stop.builddatetime;
		}
	},
	methods: {
		formatDate(date) {
			if (!date) return null;

			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		goTo() {
			let visit = btoa(`{ "id": ${this.stop.visitid} }`);
			let stop = this.stop && this.stop.id ? btoa(`{ "id": ${this.stop.id} }`) : btoa(`{ "id": ${this.stop.stopid} }`);

			this.$router.push('/visit/update/' + visit + '/stop/read/' + stop);
		},
		getDateTime() {
			let date = new Date();
			let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
			let month = date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth();
			let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
			let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
			let string = day + '' + month + '' + date.getFullYear() + '' + hour + '' + minutes;

			return string;
		},
		convertDatesFromStopAIS(date) {
			return this.$dateTimeUtils.getLocalFormattedDate(date, `${this.dateFormat} ${this.timeFormat}`);
		},
		getAllInfoAis() {
			// La informacion extra
			if (this.stop.isAis && this.stop.portCallNumber) {
				const params = {
					imo: this.stop.imo,
					mmsi: this.stop.mmsi,
					portCallNumber: this.stop.portCallNumber,
					portAuthorityId: window.localStorage.getItem('workingPortAuthorityId')
				};

				this.$puiRequests.getRequest('/visit/getAllInfoAis', params, (response) => {
					const data = response.data;
					if (data.stop) {
						this.stop.id = data.stop.id;
					}

					// backup stop ID
					const stopId = this.stop.id;

					const stop = data.stop ? data.stop : {};
					const ship = data.ship ? data.ship : {};
					const event = data.event ? data.event : {};
					this.stop = Object.assign(this.stop, stop, ship, event);

					// restore stop ID
					this.stop.id = stopId;

					// Regenerar la vista
					this.$forceUpdate();
					// Actualizamos el panel con los datos nuevos
					var panelOptions = this.$store.getters.activePanelOptionsAIS;
					panelOptions.data = this.stop;
					this.$store.commit('updateActivePanelAIS', panelOptions);
				});
			}
		}
	},
	watch: {
		stop() {
			// Esto lo hacemos para cuando cambiamos de pestaña del mismo tipo.
			// Es decir, entre dos pestañas del tipo StopContent, para refrescar los datos
			if (this.stopIMO != this.stop.imo) {
				this.stopIMO = this.stop.imo;
				this.getAllInfoAis();
			}
		}
	}
};
</script>

<style lang="postcss">
.text-with-line::before,
.text-with-line::after {
	content: '';
	display: inline-block;
	width: 50px; /* Ancho de la línea, ajusta según tus necesidades */
	height: 1px; /* Grosor de la línea, ajusta según tus necesidades */
	background-color: #000; /* Color de la línea, ajusta según tus necesidades */
	vertical-align: middle; /* Alineación vertical con el texto */
}
</style>
